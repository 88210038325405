<template>
  <div class="card-body col-md-12">
    <div class="ml-1 row d-flex justify-content-between align-items-center">
      <div class="row col-12 col-md-12">
        <div v-for="item in account_list" :key="item.id" class="w-100 col-12 row">
          <b-card
            border-variant="primary"
            header-text-variant="white"
            class="col-4 mr-1"
            :class="{ 'bg-primary text-white': item.is_disbursement_account }"
            align="left">
            <div class="row">
              <b-card-text>{{ item.bank.name }}&nbsp;
                <!-- <el-tag v-if="item.status == 'active'" size="small" effect="dark" :type="item.status_color">{{ item.status_str }}</el-tag> -->
                <el-popover
                  placement="right"
                  width="400"
                  trigger="hover">
                  <h6 class="mb-2">Status</h6>
                  <p class="mb-2" style="white-space: pre-line; overflow-wrap: break-word;">{{ item.status_description }}</p>
                  <el-tag slot="reference" size="small" effect="dark" :type="item.status_color">{{ item.status_str }}</el-tag>
                </el-popover>
              </b-card-text>
            </div>
            <div class="row mt-2 justify-content-between">
              <b-card-text>
                Account Number<h6>{{ censored(item.account_number) }}</h6>
              </b-card-text>
              <b-card-text>
                Account Name<h6>{{ truncate(item.account_name, { length: 20 }) }}</h6>
              </b-card-text>
            </div>
          </b-card>
          <div class="card col-7">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-baseline">
                <span class="mb-0">Mari Shop Balance</span>
                <div class="mb-0">
                  <el-button @click="show_modal_withdraw = true" size="mini" type="primary">Withdraw</el-button>
                  <el-button @click="show_modal_withdraw_history = true" size="mini" type="info">History</el-button>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-12 col-xl-12">
                  <h6 class="mb-2 font-weight-bold">Rp. {{ item.balance.toLocaleString('id-ID') }}</h6>
                </div>
                <el-alert type="info" title="Untuk menghindari kegagalan penarikan dana manual, kami sarankan untuk menghindari penarikan dana pada dini hari." :closable="false" show-icon></el-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-6">
        <h5>Settlement Histories</h5>
      </div>
      <div class="col-6">
        <el-date-picker
          v-model="filter.date_range"
          :picker-options="date_picker_options"
          @change="dateCheck"
          type="daterange"
          size="small"
          start-placeholder="Start date"
          end-placeholder="End date"
          class="float-right"></el-date-picker>
      </div>
    </div>
    <div class="table-responsive mt-2">
      <table class="table table-hover" v-loading="loading.trx_list">
        <thead>
          <tr>
            <th>Settlement Date</th>
            <th>Amount</th>
            <th>Ref. Number</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in trx_list" :key="item.id">
            <td>{{ item.created_at_str }}</td>
            <td>{{ item.amount_str }}</td>
            <td>{{ item.reference_number }}</td>
            <td>
              <button alt="View Detail" @click="detailTransaction(item)" class="btn btn-outline-primary btn-sm">
                View Detail
              </button> &nbsp;
            </td>
          </tr>
        </tbody>
      </table>
      <el-empty :image="emptyTransaction" v-if="total_row == 0" description="No data"></el-empty>
      <b-pagination v-if="total_row > page_limit" v-model="currentPage" :total-rows="total_row" :per-page="page_limit"/>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { truncate } from 'lodash';
import trxApi from '@/api/transactions';
// import profileApi from '@/api/profile';
// import base64File from '@/library/base64File';
import emptyTransaction from '../../../assets/images/undraw/empty-transaction.svg';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'SettlementHistories',
  props: {
    account_list: {
      type: Array,
      default: () => [],
    },
  },
  components: {
  },
  data() {
    return {
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 90 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      truncate,
      emptyTransaction,
      page: 1,
      loading: {
        create_account: false,
        trx_list: false,
      },
      form: {},
      show_modal_add: false,
      show_modal_withdraw: false,
      show_modal_withdraw_history: false,
      bank_list: [],
      loader: null,
      loaderStack: 0,
      drEvent: null,
      total_row: 0,
      page_limit: 10,
      trx_list: [],
      trx: {},
      show_modal_trx: false,
      trx_details: [],
      filter: {
        date_range: [
          moment().startOf('month').format(),
          moment().format(),
        ],
      },
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.getTransactionList();
      },
    },
    workspaces() {
      return this.$store.state.workspace.workspaces.map((workspace) => ({
        text: workspace.name,
        value: workspace._id,
      }));
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    canCloseModalAdd() {
      return !this.loading.create_account;
    },
    userProfile() {
      return this.$store.state.auth.userProfile || {};
    },
    companyProfile() {
      return this.$store.state.auth.companyProfile || {};
    },
  },
  methods: {
    dateCheck() {
      const [start_date, end_date] = this.filter.date_range;
      const minTime = moment(start_date);
      const maxTime = moment(end_date);
      const diff = maxTime.diff(moment(minTime), 'd');
      if (diff > 90) {
        this.$message({
          title: this.$t('general.error.general'),
          type: 'warning',
          message: 'Reporting period that can be selected for 90 days',
          duration: 10 * 100,
          showClose: true,
        });
        this.filter.date_range = [
          moment(end_date).subtract(90, 'days').format(),
          end_date,
        ];
      }
      this.getTransactionList();
    },
    censored(str) {
      const end = str.slice(-4);
      return end.padStart(12, '*');
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async getTransactionList() {
      this.loading.trx_list = true;
      const response = await trxApi.settlementHistories({
        workspace_id: this.activeWorkspace._id,
        query: {
          page: this.page,
          start_date: this.filter.date_range[0],
          end_date: this.filter.date_range[1],
        },
      });
      this.loading.trx_list = false;
      popupErrorMessages(response);
      this.trx_list = response.data.rows.map((v) => {
        v.amount_str = `Rp. ${(v.amount || 0).toLocaleString('id-Id')}`;
        v.created_at_str = moment(v.created_at).format('DD MMM YYYY HH:mm');
        return v;
      });
      this.total_row = response.data.count;
    },
    detailTransaction(item) {
      this.$router.push({
        name: 'SettlementDetailCommerce',
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>
