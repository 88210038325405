<template>
  <b-modal size="xl" :no-close-on-esc="!canCloseModal" :no-close-on-backdrop="!canCloseModal" v-model="computedShowModal" :title="$t('payments.modal_withdraw.title_history')" hide-footer @hidden="closeModalAdd">
    <div class="table-responsive mt-2">
      <table class="table table-hover" v-loading="loading.trx_list">
        <thead>
          <tr>
            <th>Withdraw Date</th>
            <th>Description</th>
            <th>Withdraw By</th>
            <th>Amount</th>
            <th>Transfer Fee</th>
            <th>Total Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in trx_list" :key="item.id">
            <td>{{ item.created_at_str }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.creator_name }}</td>
            <td>Rp. {{ item.amount.toLocaleString('id-ID') }}</td>
            <td>Rp. {{ item.transfer_fee.toLocaleString('id-ID') }}</td>
            <td>Rp. {{ (item.transfer_fee + item.amount).toLocaleString('id-ID') }}</td>
            <td><el-tag size="small" effect="dark" :type="item.status_color">{{ item.status_str }}</el-tag></td>
          </tr>
        </tbody>
      </table>
      <el-empty :image="emptyTransaction" v-if="total_row == 0" description="No data"></el-empty>
      <b-pagination v-if="total_row > page_limit" v-model="currentPage" :total-rows="total_row" :per-page="page_limit"/>
    </div>
    <div class="d-flex flex-row-reverse mt-2">
      <el-button size="small" @click="closeModalAdd" :disabled="!canCloseModal" type="secondary">{{ $t('general.close') }}</el-button>
    </div>
  </b-modal>
</template>
<script>
import moment from 'moment';
import transactionApi from '../../../api/transactions';
import popupErrorMessages from '../../../library/popup-error-messages';
import emptyTransaction from '../../../assets/images/undraw/empty-transaction.svg';

export default {
  name: 'CreateSettlementAccount',
  props: {
    showModalWithdraw: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emptyTransaction,
      current_step: 1,
      total_row: 0,
      page_limit: 10,
      transfer_fee: 5000,
      form: {
        amount: null,
      },
      loading: {
        trx_list: false,
      },
      show_modal: this.showModalWithdraw,
      withdraw_response: {},
      trx_list: [],
      page: 1,
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    canCloseModal() {
      return !this.loading.do_withdraw;
    },
    companyProfile() {
      return this.$store.state.auth.companyProfile || {};
    },
    computedShowModal: {
      get() {
        return this.show_modal;
      },
      set(val) {
        this.show_modal = val;
      },
    },
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.loadHistories();
      },
    },
  },
  methods: {
    censored(str) {
      const end = str.slice(-4);
      const reserve_percentage = 0.3;
      return str.slice(0, parseInt(str.length * reserve_percentage)) + end.padStart(12, '*');
    },
    closeModalAdd() {
      this.reset();
      this.$emit('closeModalAdd');
    },
    reset() {
      this.current_step = 1;
      this.form = {
        amount: null,
      };
      this.withdraw_response = {};
    },
    handleSendFile(file, target) {
      this.$emit('handleSendFile', file, target);
    },
    async loadHistories() {
      this.loading.trx_list = true;
      const response = await transactionApi.payoutHistories({
        workspace_id: this.activeWorkspace._id,
        query: {
          page: this.page,
        },
      });
      this.loading.trx_list = false;
      if (response.error) {
        popupErrorMessages(response);
      } else {
        const status_str = {
          success: 'Success',
          failed: 'Failed',
        };
        const status_color = {
          success: 'success',
          failed: 'danger',
        };
        this.trx_list = response.data.rows.map((v) => {
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          v.status_str = status_str[v.status];
          v.status_color = status_color[v.status];
          v.creator_name = v.user.name;
          return v;
        });
        this.total_row = response.data.count;
      }
    },
  },
  watch: {
    showModalWithdraw() {
      if (this.showModalWithdraw) {
        this.loadHistories();
      }
      this.show_modal = this.showModalWithdraw;
    },
  },
};
</script>
